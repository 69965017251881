import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IconType, injectEnvironment } from '@cf/core';
import { IconComponent } from '@cf/shared/ui/icons';
import { TextComponent } from '@cf/shared/ui/typography';
import { AccountService } from '@cf/temp/services';
import { TranslateModule } from '@ngx-translate/core';

import CodeClient = google.accounts.oauth2.CodeClient;
import CodeResponse = google.accounts.oauth2.CodeResponse;
import ClientConfigError = google.accounts.oauth2.ClientConfigError;

@Component({
  selector: 'cf-google-button',
  templateUrl: './google-button.component.html',
  styleUrls: ['./google-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, TextComponent, NgIf, TranslateModule],
  standalone: true,
})
export class GoogleButtonComponent implements AfterViewInit {
  @Input() disabled = false;

  @Input() buttonName!: string;

  @Input() isCalendar = false;

  @Input() isContacts = false;

  @Input() icon: IconType = 'google';

  @Output() callback = new EventEmitter<CodeResponse>();

  @Output() errorCallback: EventEmitter<ClientConfigError> = new EventEmitter();

  constructor(private readonly accountService: AccountService) {}

  private codeClient!: CodeClient;

  private codeClientForContacts!: CodeClient;

  private environment = injectEnvironment();

  ngAfterViewInit() {
    if (this.isCalendar) {
      this.icon = 'google_calendar';
    }
    setTimeout(() => {
      this.initGoogle();
    }, 500);
  }

  public connectGoogleCalendar(): void {
    if (this.isCalendar) {
      this.codeClient?.requestCode();
    }
    if (this.isContacts) {
      this.codeClientForContacts?.requestCode();
    }
  }

  private initGoogle(): void {
    this.codeClient = google.accounts.oauth2.initCodeClient({
      client_id: this.environment.googleClientId,
      enable_serial_consent: false,
      scope: 'profile email https://www.googleapis.com/auth/calendar',
      redirect_uri: 'postmessage',
      callback: (codeResponse: CodeResponse) => {
        if (codeResponse.code) {
          this.callback.emit(codeResponse);
        }
      },
      error_callback: (error: ClientConfigError) => {
        this.errorCallback.emit(error);
      },
    });

    this.codeClientForContacts = google.accounts.oauth2.initCodeClient({
      client_id: this.environment.googleClientId,
      enable_serial_consent: false,
      scope:
        // eslint-disable-next-line max-len
        'profile email https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/directory.readonly https://www.googleapis.com/auth/contacts.other.readonly',
      redirect_uri: 'postmessage',
      callback: (codeResponse: CodeResponse) => {
        if (codeResponse.code) {
          this.accountService.connectContactsFromGoogle(codeResponse).subscribe(() => {
            setTimeout(() => {
              this.accountService.getAccountProfile();
            }, 1500);
          });
        }
      },
      error_callback: (error: ClientConfigError) => {
        this.errorCallback.emit(error);
      },
    });
  }
}
